import React from 'react';
import NewVideosList from '../latest videos/LateVid';
import NewUsersList from '../user account/UserA';
import PendingUsersList from '../pendingUserList/PendingUsersList';
import TopRatedVideosList from '../topRatedVideosList/TopRatedVideosList';
import AnalyticsCharts from '../analyticCharts/AnalyticsCharts';
import LastestVid from '../latest videos/LateVid'

const Home = () => {
  return (
    <div className="home-container">
      <div className="grid-container">
      <div className="section full-width">
          {/* Add your content for the fifth section here */}
          <AnalyticsCharts />
        </div>
        <div className="section">
          {/* Add your content for the second section here */}
          <NewUsersList />
        </div>

        <div className="section">
          {/* Add your content for the third section here */}
          <PendingUsersList />
        </div>
        <div className="section">
          {/* Add your content for the fourth section here */}
          <TopRatedVideosList />
        </div>
        
      </div>
    </div>
  );
}

export default Home;
