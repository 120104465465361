// LanguagePage.jsx

import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import './LanguagePage.scss'
import Navbar from '../Topbar';

const LanguagePage = () => {
  const [selectedLanguage, setSelectedLanguage] = useState('en-US'); // Default language

  const handleChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  return (
    <div className="Languages">
      <Navbar />
      <div className="language-page-container">
      <h1>Languages</h1>
      <div className="language-dropdown">
        <FormControl fullWidth>
          <InputLabel id="language-select-label">Select Language</InputLabel>
          <Select
            labelId="language-select-label"
            id="language-select"
            value={selectedLanguage}
            label="Select Language"
            onChange={handleChange}
          >
            <MenuItem value={'en-US'}>English</MenuItem>
            <MenuItem value={'fr-FR'}>Affan Oromo</MenuItem>
            <MenuItem value={'fr-FR'}>Amharic</MenuItem>
            {/* Add more language options as needed */}
          </Select>
        </FormControl>
      </div>
    </div>
    </div>
    
  );
};

export default LanguagePage;
