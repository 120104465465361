import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './pages/login/AdminLogin';
import Home from './pages/home/Home';
import Creator from './pages/Add-content-creator/Add-Content-Creator';
import LateVid from './pages/latest videos/LateVid';
import UserA from './pages/user account/UserA';
import Sidebar from './componet/sidebar/sidebar';
import Settings from './pages/settings/Settings';
import Logout from './pages/logout/Logout';
import SearchPage from './componet/topbar/comp/Search';
import NotificationsPage from './componet/topbar/comp/NotificationsPage';
import LanguagePage from './componet/topbar/comp/LanguagePage';
import AccountPage from './componet/topbar/comp/AccountPage';
import VideoUpload from './pages/videoUpload/VideoUpload';

const App = () => {
  return (
    <div className="app">
      <BrowserRouter>
        <Sidebar />
        <div className="content">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/Add-Content-Creator" element={<Creator />} />
            <Route path="/LateVid" element={<LateVid />} />
            <Route path="/UserA" element={<UserA />} />
            <Route path="/Settings" element={<Settings />} />
            <Route path="/Logout" element={<Login />} />
            <Route path="/Search" element={<SearchPage />} />
            <Route path="/Notifications" element={<NotificationsPage />} />
            <Route path="/Language" element={<LanguagePage />} />
            <Route path="/Account" element={<AccountPage />} />
            <Route path="/VideoUpload" element={<VideoUpload />} />
            {/* Add other routes here */}
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
