import "./login.scss";
import { useNavigate } from 'react-router-dom';

export default function AdminLogin() {
  const navigate  = useNavigate();

  const handleHome = () => {
    navigate("/Home");
  };

  return (
    <div className="login">
      <div className="top">
        <div className="wrapper">
          <img
            className="logo"
            src="../image/photo_2024-03-11_11-38-35-removebg-preview.png"
            alt=""
          />
        </div>
      </div>
      <div className="container">
        <form>
          <h4>Sign In</h4>
          <input type="email" placeholder="Phone number" />
          <input type="password" placeholder="OTP" />
          <button onClick={handleHome} className="loginButton">Sign In</button>
          <span>
            New to Mind pulse? <b>Sign up now.</b>
          </span>
          <small>
            This page is protected by Google reCAPTCHA to ensure you're not a
            bot. <b>Learn more</b>.
          </small>
        </form>
      </div>
    </div>
  );
}
