// SearchPage.js
import React, { useState } from 'react';
import './SearchPage.scss';
import Navbar from '../Topbar'; // Import SCSS file for styles

const SearchPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    if (!searchQuery.trim()) {
      alert('Please enter a search query.');
      return;
    }

    setLoading(true);
    // Simulating an API call with setTimeout
    setTimeout(() => {
      // Placeholder search results (replace with actual search logic)
      const results = [
        { id: 1, title: 'Sample Result 1', url: '#' },
        { id: 2, title: 'Sample Result 2', url: '#' },
        { id: 3, title: 'Sample Result 3', url: '#' },
      ];
      setSearchResults(results);
      setLoading(false);
    }, 1500); // Simulate 1.5 seconds delay
  };

  return (
    <div className="navigation">
      <Navbar />
      <div className="search-page-container">
        <h1>Search Page</h1>
        <div className="search-form">
          <input
            type="text"
            placeholder="Enter your search query"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button onClick={handleSearch}>Search</button>
        </div>
        <div className="search-results">
          <h2>Search Results</h2>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ul>
              {searchResults.map((result) => (
                <li key={result.id}>
                  <a href={result.url}>{result.title}</a>
                </li>
              ))}
              {searchResults.length === 0 && <p>No results found.</p>}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchPage;
