import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaHome, FaVideo, FaUser, FaAngleDown, FaSignOutAlt, FaCog, FaUpload } from 'react-icons/fa';
import './sidebar.scss';

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
      <div className="sidebar-header">
        <h3></h3>
      </div>
      <ul className="sidebar-menu">
        <li>
          <NavLink to="/Home" activeClassName="active-link">
            <FaHome className="icon" />
            <span>Home</span>
          </NavLink>
        </li>
        <li>

          <NavLink to="/Add-Content-Creator" activeClassName="active-link">
            <FaHome className="icon" />
            <span>Add Content Creator</span>
          </NavLink>
        </li>
        
        <li>
          <NavLink to="/LateVid" activeClassName="active-link">
            <FaVideo className="icon" />
            <span>Latest Videos</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/UserA" activeClassName="active-link">
            <FaUser className="icon" />
            <span>User Accounts</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/VideoUpload" activeClassName="active-link">
            <FaUpload className="icon" />
            <span>Upload Video</span>
          </NavLink>
        </li>
        {/* Add more menu items and submenus */}
        <li className="submenu">
          <div className="submenu-header" onClick={toggleCollapse}>
            <span>
              <FaAngleDown className="icon" />
              Analytics
            </span>
          </div>
          <ul className="submenu-items">
            <li>
              <NavLink to="/Analytics1" activeClassName="active-link">
                <span>Analytics 1</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/Analytics2" activeClassName="active-link">
                <span>Analytics 2</span>
              </NavLink>
            </li>
          </ul>
        </li>
      </ul>
      <div className="sidebar-footer">
        <NavLink to="/Settings" className="footer-link">
          <FaCog className="footer-icon" />
          <span>Settings</span>
        </NavLink>
        <NavLink to="/Logout" className="footer-link">
          <FaSignOutAlt className="footer-icon" />
          <span>Logout</span>
        </NavLink>
      </div>
    </div>
  );
};

export default Sidebar;
