import React from 'react';
import './topRatedVideosList.scss'
const TopRatedVideosList = () => {
  // Sample data for top-rated videos
  const videos = [
    {
      id: 1,
      title: 'Video Title 1',
      thumbnail: 'https://via.placeholder.com/150',
      description: 'Description for Video 1',
      views: 1000,
      likes: 500,
    },
    {
      id: 2,
      title: 'Video Title 2',
      thumbnail: 'https://via.placeholder.com/150',
      description: 'Description for Video 2',
      views: 800,
      likes: 400,
    },
    {
      id: 3,
      title: 'Video Title 3',
      thumbnail: 'https://via.placeholder.com/150',
      description: 'Description for Video 3',
      views: 1200,
      likes: 600,
    },
  ];

  return (
    <div className="top-rated-videos">
      <h2>Top Rated Videos</h2>
      <div className="video-list">
        {videos.map(video => (
          <div key={video.id} className="video-card">
            <img src={video.thumbnail} alt={video.title} />
            <div className="video-info">
              <h3>{video.title}</h3>
              <p>{video.description}</p>
              <div className="stats">
                <p>Views: {video.views}</p>
                <p>Likes: {video.likes}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopRatedVideosList;
