// Import necessary modules and components
import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import './topbar.scss'
import { AccountCircle, NotificationsNone, Language, Settings, Search } from "@material-ui/icons";


const Navbar1 = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  // Function to handle scrolling
  window.onscroll = () => {
    setIsScrolled(window.pageYOffset === 0 ? false : true);
    return () => (window.onscroll = null);
  };

  // Function to handle navigation
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className={isScrolled ? "navbar scrolled" : "navbar"}>
      <div className="container">
        <div className="topbarWrapper">
          <div className="topLeft">
            <span className="logo">Admin panel</span>
          </div>
          <div className="topRight">
          </div>
          <div className="center">
          </div>
          <div className="right">
            <div className="search-icon" onClick={() => handleNavigation("/Search")}>
              <Search style={{ color: "white" }} />
            </div>
          </div>
          <div className="topbarIconContainer" onClick={() => handleNavigation("/Notifications")}>
            <NotificationsNone style={{ color: "white" }} />
            <span className="topIconBadge">2</span>
          </div>
          <div className="topbarIconContainer" onClick={() => handleNavigation("/Language")}>
            <Language style={{ color: "white" }} />
            <span className="topIconBadge">2</span>
          </div>
          <div className="topbarIconContainer" onClick={() => handleNavigation("/Settings")}>
            <Settings style={{ color: "white" }} />
          </div>
          <div className="topbarIconContainer" onClick={() => handleNavigation("/Account")}>
            <AccountCircle style={{ color: "white" }} className="account-icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar1;
