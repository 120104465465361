// firebaseConfig.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBdt4h6sxc2K-jPU5JnkeX7nMYEZI_ozdQ",
  authDomain: "ethio-heros.firebaseapp.com",
  projectId: "ethio-heros",
  storageBucket: "ethio-heros.appspot.com",
  messagingSenderId: "421634224380",
  appId: "1:421634224380:web:40b5f0771f45419d536779",
  measurementId: "G-NF6MFWB534"
};

firebase.initializeApp(firebaseConfig);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
export default firebase;