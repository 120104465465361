import React from 'react';
import Image from '../avatars/1.jpg'
import './avatar.scss'
const Avatar = () => {
  return (
    <img src={Image} alt="User Avatar" className="user-avatar" />
  );
}

export default Avatar;
