import React, { useState, useEffect } from 'react';
import './latevid.scss';
import Navbar from '../../componet/topbar/Topbar';
import firebase from '../../firebaseConfige'; // Import your Firebase configuration

const LateVid = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    fetchVideosFromFirebase();
  }, []);

  const fetchVideosFromFirebase = async () => {
    try {
      const userVideosRef = firebase.firestore().collectionGroup('user-videos');
      const snapshot = await userVideosRef.get();
      const fetchedVideos = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          title: data.title,
          description: data.description,
          videoURL: data.videoURL,
          uploader: data.uploader,
          userId: doc.ref.parent.parent.id, // Assuming the user ID is stored in the parent document
          status: data.status // Assuming there's a status field in the video document
        };
      });
      setVideos(fetchedVideos);
    } catch (error) {
      console.error('Error fetching videos:', error);
    }
  };

  const approveVideo = async (videoId) => {
    try {
      await firebase.firestore().collection('user-videos').doc(videoId).update({
        status: 'approved'
      });
      // Optionally, you can update the local state to reflect the change immediately
      setVideos(videos.map(video => video.id === videoId ? { ...video, status: 'approved' } : video));
    } catch (error) {
      console.error('Error approving video:', error);
    }
  };

  const declineVideo = async (videoId) => {
    try {
      await firebase.firestore().collection('user-videos').doc(videoId).update({
        status: 'declined'
      });
      // Optionally, you can update the local state to reflect the change immediately
      setVideos(videos.map(video => video.id === videoId ? { ...video, status: 'declined' } : video));
    } catch (error) {
      console.error('Error declining video:', error);
    }
  };

  const renderVideo = (video) => {
    return (
      <div key={video.id} className="videoContainer">
        <video controls>
          <source src={video.videoURL} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="videoInfo">
          <h3>{video.title}</h3>
          <p>{video.description}</p>
          <p>Uploader: {video.uploader}</p>
          <p>Status: {video.status}</p>
        </div>
        <div className="actionButtons"> {/* Move actionButtons outside of videoInfo */}
          {/* Approve and Decline buttons */}
          {video.status === 'pending' && (
            <div>
              <button className="app" onClick={() => approveVideo(video.id)}>Approve</button>
              <button className="dic" onClick={() => declineVideo(video.id)}>Decline</button>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="lateVid">
      <Navbar />
      <div className="lateVidContent">
        <h2>Latest Video Upload</h2>
        {videos.map(renderVideo)}
      </div>
    </div>
  );
};

export default LateVid;
