import React, { useState } from 'react';
import "./pendingUserList.scss";
import Avatar from './../../image/avatars/Avatars'; // Assuming Avatar component is available

const PendingUsersList = () => {
  // Dummy data for pending users (replace with actual data)
  const [pendingUsers, setPendingUsers] = useState([
    { id: 1, name: 'John Doe', email: 'john@example.com' },
    { id: 2, name: 'Jane Smith', email: 'jane@example.com' },
    { id: 3, name: 'Michael Johnson', email: 'michael@example.com' },
    // Add more users as needed
  ]);

  const handleApprove = (id) => {
    // Logic to approve user (update database, etc.)
    console.log(`User with ID ${id} approved`);
    // Remove the approved user from the pending list
    setPendingUsers(pendingUsers.filter(user => user.id !== id));
  };

  const handleReject = (id) => {
    // Logic to reject user (update database, etc.)
    console.log(`User with ID ${id} rejected`);
    // Remove the rejected user from the pending list
    setPendingUsers(pendingUsers.filter(user => user.id !== id));
  };

  return (
    <div className="pending-users-list">
      <h2>Pending Users</h2>
      <ul>
        {pendingUsers.length > 0 ? (
          pendingUsers.map(user => (
            <li key={user.id}>
              <div className="user-details">
                <Avatar />
                <div>
                  <h3>{user.name}</h3>
                  <p>{user.email}</p>
                </div>
                <div className="user-actions">
                  <button onClick={() => handleApprove(user.id)}>Approve</button>
                  <button className="reject-button" onClick={() => handleReject(user.id)}>Reject</button>
                </div>
              </div>
            </li>
          ))
        ) : (
          <li>No pending users</li>
        )}
      </ul>
    </div>
  );
}

export default PendingUsersList;
