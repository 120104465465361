import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import './userA.scss';
import Navbar from '../../componet/topbar/Topbar';

const UserA = () => {
  const [accounts, setAccounts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOption, setSortOption] = useState('name'); // Default sorting by name
  const [currentPage, setCurrentPage] = useState(1);
  const [accountsPerPage] = useState(5); // Number of accounts per page
  const [editingAccountId, setEditingAccountId] = useState(null);
  const [filterOption, setFilterOption] = useState(""); // Default: show all accounts

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const snapshot = await firebase.firestore().collection('admin-user').get();
        const fetchedAccounts = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setAccounts(fetchedAccounts);
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };

    fetchAccounts();

    // Clean-up function
    return () => {};
  }, []);

  const disableAccount = async (accountId) => {
    try {
      await firebase.firestore().collection('admin-user').doc(accountId).update({ status: 'Disabled' });
      updateAccountStatus(accountId, 'Disabled');
    } catch (error) {
      console.error('Error disabling account:', error);
    }
  };

  const approveAccount = async (accountId) => {
    try {
      await firebase.firestore().collection('admin-user').doc(accountId).update({ status: 'Active' });
      updateAccountStatus(accountId, 'Active');
    } catch (error) {
      console.error('Error approving account:', error);
    }
  };

  const terminateAccount = async (accountId) => {
    try {
      await firebase.firestore().collection('admin-user').doc(accountId).delete();
      setAccounts(prevAccounts =>
        prevAccounts.filter(account => account.id !== accountId)
      );
    } catch (error) {
      console.error('Error terminating account:', error);
    }
  };

  const updateAccountStatus = (accountId, status) => {
    setAccounts(prevAccounts =>
      prevAccounts.map(account =>
        account.id === accountId ? { ...account, status } : account
      )
    );
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  const handleSort = (e) => {
    setSortOption(e.target.value);
  };

  const handleFilter = (e) => {
    setFilterOption(e.target.value);
  };

  const indexOfLastAccount = currentPage * accountsPerPage;
  const indexOfFirstAccount = indexOfLastAccount - accountsPerPage;
  const currentAccounts = accounts.slice(indexOfFirstAccount, indexOfLastAccount);

  const filteredAccounts = currentAccounts.filter(account =>
    account.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
    (filterOption === "" || account.status === filterOption)
  );

  const sortedAccounts = filteredAccounts.sort((a, b) => {
    if (sortOption === 'name') {
      return a.name.localeCompare(b.name);
    } else if (sortOption === 'date') {
      return new Date(b.createdAt) - new Date(a.createdAt); // Assuming createdAt field exists
    }
    // Add more sort options if needed
    return 0;
  });

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const editAccount = (accountId) => {
    setEditingAccountId(accountId);
  };

  const saveAccountChanges = async (accountId, updatedData) => {
    try {
      await firebase.firestore().collection('admin-user').doc(accountId).update(updatedData);
      setAccounts(prevAccounts =>
        prevAccounts.map(account =>
          account.id === accountId ? { ...account, ...updatedData } : account
        )
      );
      setEditingAccountId(null);
    } catch (error) {
      console.error('Error updating account:', error);
    }
  };

  const cancelEdit = () => {
    setEditingAccountId(null);
  };

  return (
    <div className="user">
      <Navbar />
      <div className="userA">
        <h2>Accounts List</h2>
        <div className="controls">
          <input
            type="text"
            placeholder="Search by name"
            value={searchQuery}
            onChange={handleSearch}
          />
          <select value={sortOption} onChange={handleSort}>
            <option value="name">Sort by Name</option>
            <option value="date">Sort by Date</option>
            {/* Add more sort options here if needed */}
          </select>
          <select value={filterOption} onChange={handleFilter}>
            <option value="">All</option>
            <option value="Active">Active</option>
            <option value="Disabled">Disabled</option>
          </select>
        </div>
        <div className="accounts">
          {sortedAccounts.map(account => (
            <div key={account.id} className="account">
              <div className="profilePicContainer">
                <img src={account.profileImage} alt={`Profile of ${account.name}`} className="profilePic" />
              </div>
              <div className="accountInfo">
                {editingAccountId === account.id ? (
                  <EditAccountForm
                    account={account}
                    onSave={(updatedData) => saveAccountChanges(account.id, updatedData)}
                    onCancel={cancelEdit}
                  />
                ) : (
                  <>
                    <p>Name: {account.name}</p>
                    <p>Status: {account.category}</p>
                    <button className='edit' onClick={() => editAccount(account.id)}>Edit</button>
                    <button className='approve' onClick={() => approveAccount(account.id)}>Approve</button>
                    <button className='disable' onClick={() => disableAccount(account.id)}>Disable</button>
                    <button className='terminte' onClick={() => terminateAccount(account.id)}>Terminate</button>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
        <Pagination
          accountsPerPage={accountsPerPage}
          totalAccounts={accounts.length}
          paginate={paginate}
        />
      </div>
    </div>
  );
};

const Pagination = ({ accountsPerPage, totalAccounts, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalAccounts / accountsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className='pagination'>
        {pageNumbers.map(number => (
          <li key={number} className='page-item'>
            <button onClick={() => paginate(number)} className='page-link'>
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

const EditAccountForm = ({ account, onSave, onCancel }) => {
  const [name, setName] = useState(account.name);
  const [category, setCategory] = useState(account.category);

  const handleSave = () => {
    onSave({ name, category });
  };

  return (
    <div className="edit-form">
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <input
        type="text"
        value={category}
        onChange={(e) => setCategory(e.target.value)}
      />
      <button onClick={handleSave}>Save</button>
      <button onClick={onCancel}>Cancel</button>
    </div>
  );
};

export default UserA;
